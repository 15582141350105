<template>
    <div class="lang-select-wrapper">
        <ul v-if="langSelectActive" class="lang-list">
            <li v-if="locale == 'ja'">
                <span v-on:click="switchLang($event, 'en')" class="lang-controll">
                    <span class="icon-lang"><img src="/images/icons/us.svg" alt="en icon"></span>
                    <span class="cur-lang">English</span>
                    <svg class="lang-arow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" data-svg="chevron-down"><path d="M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z"></path></svg>
                </span>
            </li>
            <li v-if="locale == 'en'">
                <span v-on:click="switchLang($event, 'ja')" class="lang-controll">
                    <span class="icon-lang"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><g fill="none" fill-rule="evenodd"><rect width="20" height="20" fill="#F0F0F0" rx="3"/><circle cx="10" cy="10" r="6" fill="#DD2525"/></g></svg></span>
                    <span class="cur-lang">日本語</span>
                    <svg class="lang-arow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" data-svg="chevron-down"><path d="M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z"></path></svg>
                </span>
            </li>
        </ul>
        <div v-on:click="openLangSelect" class="lang-select" :class="{ 'active': langSelectActive }">
            <span v-if="locale == 'en'" class="lang-controll">
                <span class="icon-lang"><img src="/images/icons/us.svg" alt="en icon"></span>
                <span class="cur-lang">English</span>
                <svg class="lang-arow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" data-svg="chevron-down"><path d="M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z"></path></svg>
            </span>
            <span v-if="locale == 'ja'" class="lang-controll">
                <span class="icon-lang"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><g fill="none" fill-rule="evenodd"><rect width="20" height="20" fill="#F0F0F0" rx="3"/><circle cx="10" cy="10" r="6" fill="#DD2525"/></g></svg></span>
                <span class="cur-lang">日本語</span>
                <svg class="lang-arow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" data-svg="chevron-down"><path d="M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z"></path></svg>
            </span>
        </div>
    </div>
</template>
<script>
import { fbChat } from '../mixins/fb-chat.mixin';

export default {
    data() {
        return {
            langSelectActive: false,
            locale: null
        }
    },
    mixins: [fbChat],
    methods: {
        openLangSelect(event) {
            event.stopPropagation();
            this.langSelectActive = !this.langSelectActive
        },
        switchLang(event, lang) {
            this.$root.$emit("curtain_run", true);
            this.hideChat();

            this.langSelectActive = false;

            setTimeout(() => {
                this.locale = lang;
                this.$i18n.locale = this.locale;
                this.$cookies.set('locale', this.locale);
                window.scrollTo({ top: 0 });
            }, 2000);
            
            setTimeout(() => {
                this.showChat();
                this.$root.$emit("curtain_run", false)
            }, 5000);
        },
        setLocale() {
            this.$i18n.locale = this.$cookies.get('locale');
            
            if(!this.$i18n.locale) {
                this.$i18n.locale = this.getClientLocale();
            }
            
            this.locale = this.$i18n.locale;

            if(this.$i18n.locale && (this.$i18n.locale != 'en' && this.$i18n.locale != 'ja')) {
                this.locale = "en";
                this.$i18n.locale = "en";
                this.$cookies.set('locale', "en");
            }
        },
        getClientLocale() {
            const userLang = navigator.language || navigator.userLanguage; 
            const locale = userLang.split('-');
            return locale[0];
        }
    },
    mounted() {
       this.setLocale();
       document.addEventListener('click', () => { this.langSelectActive = false });
    }
};
</script>
<style lang="scss" scoped>
@import '../scss/app';

.lang-select-wrapper {
    .lang-list {
        background: #fff;
        position: absolute;
        bottom: 40px;
        right:0;
        width: 182px;
        border: 2px solid #fff;
        border-radius: 6px;
        z-index: 999;
        li {
            padding: 10px 0;

            .lang-controll {
                padding: 2px 12px;
                font-family: $family-regular;
            }

            .lang-controll:hover {
                color: #53718f;
                background: #f1f5f8;
            }
        }
    }

    .lang-select {
        position: absolute;
        bottom: -10px;
        right: 0;
        color: #fff;
        width: 182px;
        @include font-size(17px);
        font-family: $family-regular;
        line-height: 22px;
        border-radius: 6px;
        padding: 12px 14px;
        border: 2px solid #fff;

        &.active {
            background: #fff;
            color: #191a1a;

            .lang-arow {
                fill:#191a1a;
            }
        }
    }

    .lang-controll {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .cur-lang {
        position: relative;
        left: -20px;
        top: 2px;
    }

    .lang-arow {
        fill:#fff;
    }
}
@media (max-width: 992px) {
    .lang-select-wrapper {
        display:none;
    }
}
</style>